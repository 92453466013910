import "./App.css";
import company_logo from "./left-logo-source.png";
import company_address_logo from "./78BGulBaharPark.jpg";
import erp_hms_logo from "./ERPHMS.jpg";
function App() {
  return (
    <div className="App">
      {/* <div class="bg-light"> */}

      <div class="container">
        <a href="/">
          {" "}
          <i class="fa fa-snowflake-o fa-lg text-primary mr-2"></i>
          <img src={company_logo} height={250} width={300} alt="logo" />
        </a>

        <div class="row align-items-center ">
          <div class="col-lg-5">
            <img
              className="companyImage"
              src={erp_hms_logo}
              width={600}
              alt="EMR"
            />
          </div>
          <div class="col-lg-2"></div>
          <div class="col-lg-5">
            <img
              className="companyImage"
              src={company_address_logo}
              width={600}
              alt="Alivaetch"
            />
          </div>
          {/* <div class="col-lg-6">
            <h1 class="display-4">Aliva Tech</h1>

            <p class="lead text-muted mb-0">
              <span className="addresstitle">Email:</span>&nbsp;
              <a href="mailto:info@AlivaTech.com">info@AlivaTech.com</a>&nbsp;
              <span className="addresstitle">Cell:</span>&nbsp; +92 345 418 6181
            </p>
            <p class="lead text-muted">
              <address>
                <span className="addresstitle">Head Office:</span> 374-A, Pak
                Block, Allama Iqbal Town, Lahore, Pakistan
                <br />
                <span className="addresstitle">S/W Development:</span> 90B Hali
                Road, Block B, Gulberg 2, Lahore, Pakistan
                <br />
                <span className="addresstitle">Warehouse:</span> 78B Gul Bahar
                Park, Canal Bank, Lahore, Pakistan
              </address>
            </p>
          </div> */}
        </div>
      </div>
      {/* </div> */}

      <footer class="footer">
        <div class="container text-center">
          <p class="font-italic text-muted mb-0">
            &copy; Copyrights alivatech.com all rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default App;
